import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Image from "next/legacy/image";
import { Button } from '../../ui/button';
import SquareTemplate1 from '../../assets/images/template_square_1.png';
import SquareTemplate2 from '../../assets/images/template_square_2.png';
import PortraitTemplate1 from '../../assets/images/template_portrait_1.png';
import PortraitTemplate2 from '../../assets/images/template_portrait_2.png';
import LandscapeTemplate1 from '../../assets/images/template_landscape_1.png';
import LandscapeTemplate2 from '../../assets/images/template_landscape_2.png';

const Container = styled.div`
  position: relative;
  width: 100%;
  background-color: ${ ({theme}) => theme.colors.xmasGreen };
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0 36px;
  text-align: center;
  margin-top: -200px;
`;

const Title = styled.h2`
  font-family: ${ ({theme}) => theme.fonts.cera };
  color: ${ ({theme}) => theme.colors.allWhite };
  font-size: 22px;
  line-height: 1.2;
  font-weight: 900;
  margin: 0;
  text-transform: uppercase;
`;

const Text = styled.p`
  font-family: ${ ({theme}) => theme.fonts.cera };
  color: ${ ({theme}) => theme.colors.allWhite };
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400;
  margin: 16px 0 0;
`;

const TemplateContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 32px 0 0;
  pointer-events: none;
`;

const TemplateColumn = styled.div`
  display: flex;
  flex-direction: column;
  &:last-child {
    margin-left: 16px;
  }
  > span {
    margin-bottom: 16px !important;
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
`;

const ButtonWrapper = styled.div`
  margin: 32px 0 0;
`;

type InspirationProps = {
  onCreateButtonClick: () => void;
}

export function Inspiration({onCreateButtonClick}: InspirationProps) {
  return (
    <Container>
      <Title>
        <FormattedMessage
          defaultMessage="Do you need{br}inspiration?"
          id="do_you_need_inspiration_title"
          values={ {br: <br />} }
        />
      </Title>

      <Text>
        <FormattedMessage
          defaultMessage="Here are some templates{br}we have made"
          id="do_you_need_inspiration_templates"
          values={ {br: <br />} }
        />
      </Text>

      <TemplateContainer>
        <TemplateColumn>
          <Image src={LandscapeTemplate1} alt="Lego card template" width={168} height={128} quality={100} unoptimized />
          <Image src={PortraitTemplate1} alt="Lego card template" width={168} height={223.55} quality={100} unoptimized />
          <Image src={SquareTemplate1} alt="Lego card template" width={168} height={168} quality={100} unoptimized />
        </TemplateColumn>
        <TemplateColumn>
          <Image src={SquareTemplate2} alt="Lego card template" width={168} height={168} quality={100} unoptimized />
          <Image src={LandscapeTemplate2} alt="Lego card template" width={168} height={128} quality={100} unoptimized />
          <Image src={PortraitTemplate2} alt="Lego card template" width={168} height={223.55} quality={100} unoptimized />
        </TemplateColumn>
      </TemplateContainer>

      <ButtonWrapper>
        <Button color="mediumViolet" onClick={ onCreateButtonClick }>
          <FormattedMessage
            defaultMessage="Start creating"
            id="start_creating"
          />
        </Button>
      </ButtonWrapper>
    </Container>
  );
}
