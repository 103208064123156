import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import getColor from '../hooks/get-color';
import { ColorsType } from '../components/app-theme-provider';

const Container = styled.button<Pick<ButtonType, 'round'>>`
  min-width: ${ ({round}) => round ? '0' : '168px' };
  width: ${ ({round}) => {
    switch(round) {
      case true:
        return '55px';
      case false:
      default:
        return 'auto'
    }
  } };
  height: ${ ({round}) => {
    switch(round) {
      case true:
        return '55px';
      case false:
      default:
        return '32px';
    }
  } };
  margin: 0;
  text-align: center;
  outline: none;
  background-color: ${ ({color}) => color ? getColor(color as ColorsType) : 'transparent' };
  color: ${ ({theme}) => theme.colors.allWhite };
  border: ${ ({color}) => color ? 'none' : '2px solid #FFF' };
  border-radius: ${ ({round}) => round ? '50%' : '30px' };
  padding: ${ ({round}) => round ? '0' : '4px 25px' };
  font-size: 16px;
  font-weight: 400;
  font-family: ${ ({theme}) => theme.fonts.cera };
  cursor: pointer;

  &:hover {
    background-color: ${ ({color}) => color ? rgba(getColor(color as ColorsType), 0.9) : 'transparent' };
  }
  
  &:disabled {
    opacity: 0.5;
    cursor: auto;
    &:hover {
      background-color: ${ ({color}) => color ? getColor(color as ColorsType) : 'transparent' };
    }
  }
`;

export type ButtonType = {
  children: ReactElement;
  color?: ColorsType;
  disabled?: boolean;
  round?: boolean;
  onClick?: () => void;
  data?: string;
}

export function Button({children, color, disabled, round, onClick, data}: ButtonType) {
  return (
    <Container color={ color } disabled={ disabled } round={ round } onClick={ onClick } data-button={data}>
      { children }
    </Container>
  );
}