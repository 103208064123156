import React, { ReactElement } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  text-align: center;
  margin: 16px 0 0;
`;

const Text = styled.p`
  font-family: ${ ({theme}) => theme.fonts.cera };
  color: ${ ({theme}) => theme.colors.allWhite };
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400;
  margin: 12px 0 0;
`;

type FeatureType = {
  icon: ReactElement;
  children: ReactElement;
}

export function Feature({icon, children}: FeatureType) {
  return (
    <Container>
      { icon }
      <Text>
        { children }
      </Text>
    </Container>
  );
}
