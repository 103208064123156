import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Image from "next/legacy/image";
import { Button } from '../../ui/button';
import LegoCharacters from '../../assets/images/christmas_lego.png';
import { Feature } from '../../ui/feature';
import HeartIcon from '../../assets/icons/icon_heart.svg';
import PictureIcon from '../../assets/icons/icon_picture.svg';
import SocialIcon from '../../assets/icons/icon_social.svg';

const Container = styled.div`
  background-color: ${ ({theme}) => theme.colors.xmasPurple };
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 65px 0 36px;
  margin-top: 120px;
  transform: translateY(-200px);

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    clip-path: polygon(0 0,
    100% 60px,
    100% 100%,
    0 100%);
    background-color: ${ ({theme}) => theme.colors.xmasPurple };
    height: 60px;
    transform: translateY(-60px);
  }
`;

const LegoCharactersContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -180px);
  z-index: 1;

  span {
    width: 353px !important;
    height: 220px !important;
  }
`;

const Title = styled.h2`
  font-family: ${ ({theme}) => theme.fonts.cera };
  color: ${ ({theme}) => theme.colors.allWhite };
  font-size: 22px;
  line-height: 1.2;
  font-weight: 900;
  margin: 0;
  text-transform: uppercase;
`;

const ButtonWrapper = styled.div`
  margin: 20px 0 0;
`;

type HowItWorksProps = {
  id: string;
  onCreateButtonClick: () => void;
}

export function HowItWorks({id, onCreateButtonClick}: HowItWorksProps) {
  return (
    <Container id={id}>
      <LegoCharactersContainer>
        <Image src={ LegoCharacters } alt="" width={ 353 } height={ 220 } quality={ 100 } />
      </LegoCharactersContainer>
      <Title>
        <FormattedMessage
          defaultMessage="How it works"
          id="how_it_works_title"
        />
      </Title>

      <Feature icon={ (<HeartIcon />) }>
        <FormattedMessage
          defaultMessage="Tell us about yourself, what are{br}your passions? We need it to design{br}your digital ticket"
          id="how_it_works_passions"
          values={ {br: <br />} }
        />
      </Feature>

      <Feature icon={ (<PictureIcon />) }>
        <FormattedMessage
          defaultMessage="Take a photo, choose the size{br}and graphics you prefer to preview{br}your card"
          id="how_it_works_take_photo"
          values={ {br: <br />} }
        />
      </Feature>

      <Feature icon={ (<SocialIcon />) }>
        <FormattedMessage
          defaultMessage="Save the card in your phone's gallery{br}and share it with your LEGO® friends,{br}or print it out"
          id="how_it_works_save_card"
          values={ {br: <br />} }
        />
      </Feature>

      <ButtonWrapper>
        <Button color="mediumViolet" onClick={ onCreateButtonClick }>
          <FormattedMessage
            defaultMessage="Start creating"
            id="start_creating"
          />
        </Button>
      </ButtonWrapper>
    </Container>
  );
}
