import React, { useCallback, useEffect } from 'react';
import type { NextPage } from 'next';
import Router from 'next/router';
import Head from 'next/head';
import styled from 'styled-components';
import { scroller as scroll } from 'react-scroll';
import { useDispatch } from 'react-redux';
import { resetCard } from '../store/actions/reset-card';
import { Hello } from '../components/homepage/hello';
import { HowItWorks } from '../components/homepage/how-it-works';
import { Inspiration } from '../components/homepage/inspiration';


const Container = styled.div`
  overflow: hidden;
`;

const Home: NextPage = () => {
  const dispatch = useDispatch();
  const onCreateButtonClick = useCallback(() => Router.push('/create/passions'), []);
  const onHowItWorksButtonClick = useCallback(() => scroll.scrollTo('how-it-works', {
    duration: 800,
    delay: 100,
    smooth: true,
    offset: -255,
  }), []);

  useEffect(() => {
    dispatch(resetCard());
  }, [dispatch]);

  return (
    <>
      <Head>
        <title>Digital Card - Welcome | LEGO®</title>
      </Head>
      <Container>
        <Hello
          onCreateButtonClick={ onCreateButtonClick }
          onHowItWorksButtonClick={ onHowItWorksButtonClick }
        />
        <HowItWorks onCreateButtonClick={ onCreateButtonClick } id="how-it-works" />
        <Inspiration onCreateButtonClick={ onCreateButtonClick } />
      </Container>
    </>
  );
};

export default Home;
