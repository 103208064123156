import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Image from "next/legacy/image";
import Santa from '../../assets/images/lego-santa-new.png';
import CDecoration1 from '../../assets/images/snow-right.png';
import CDecoration2 from '../../assets/images/snow-left.png';
import ImgDecorationDX from '../../assets/images/img-center-dx.png';
import ImgDecorationSX from '../../assets/images/img-center-sx.png';
import { Button } from '../../ui/button';

const Container = styled.div`
  position: relative;
  width: 100%;
  background-color: ${ ({theme}) => theme.colors.xmasAqua };
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0 270px;
  text-align: center;
  gap: 0.5rem;
`;

const Title = styled.h1`
  font-family: ${ ({theme}) => theme.fonts.cera };
  color: ${ ({theme}) => theme.colors.allWhite };
  font-size: 43px;
  line-height: 1.2;
  font-weight: 900;
  margin: 0;
  text-transform: uppercase;
`;

const Subtitle = styled.h2`
  font-family: ${ ({theme}) => theme.fonts.cera };
  color: ${ ({theme}) => theme.colors.allWhite };
  font-size: 22px;
  line-height: 1.2;
  font-weight: 700;
  margin: 16px 0 0;
`;

// const ChristmasDecoration1 = styled(Decoration1)`
//   position: absolute;
//   top: 0;
//   right: 0;
//   pointer-events: none;
// `;

const ChristmasDecoration1Image = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
  width: 100%;
  text-align: right;
  padding-left: 65%;
`;

// const ChristmasDecoration2 = styled(Decoration2)`
//   position: absolute;
//   top: 0;
//   left: 0;
//   transform: translateY(-30px);
// `;

const ChristmasDecoration2Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: left;
  padding-right: 65%;
`;

const SantaContainer = styled.div`
  position: relative;
  padding-top: 25px;
  padding-bottom: 60px;
  margin: 20px 0 0;
  pointer-events: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SantaImage = styled.div`
  margin: 0;
`;

const Text = styled.p`
  font-family: ${ ({theme}) => theme.fonts.cera };
  color: ${ ({theme}) => theme.colors.allWhite };
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400;
  margin: 0;
`;

const CallToAction = styled.div`
  margin: -74px 0 12px;
  z-index: 1;
`;

const ButtonsContainer = styled.div`
  margin: 24px 0 0;

  button:last-child {
    margin: 12px 0 0;
  }
`;

const TitleDisclaimer = styled.p`
  font-family: ${ ({theme}) => theme.fonts.cera };
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 4px;
  color: white;
`

const LeftImageDecorationContainer = styled.div`
  position: relative;
  flex: 1;
  max-width: 152px;
`;

const RightImageDecorationContainer = styled.div`
  position: relative;
  flex: 1;
  max-width: 152px;
`;

type HelloProps = {
  onCreateButtonClick: () => void;
  onHowItWorksButtonClick: () => void;
}

export function Hello({onCreateButtonClick, onHowItWorksButtonClick}: HelloProps) {
  return (
    <Container>
      <Title>
        <FormattedMessage defaultMessage="Hello!" id="homepage_hello" />
      </Title>
      <Subtitle>
        <FormattedMessage
          defaultMessage="LEGO® wants to offer{br}you a personalized greeting{br}card this year."
          id="homepage_subtitle"
          values={{ br: <br /> }}
        />
      </Subtitle>
      <ChristmasDecoration1Image>
        <Image src={CDecoration1} alt="" width={180} height={322} quality={100} priority />
      </ChristmasDecoration1Image>
      <ChristmasDecoration2Image>
        <Image src={CDecoration2} alt="" width={200} height={350} quality={100} priority />
      </ChristmasDecoration2Image>
      <SantaContainer>
        <LeftImageDecorationContainer>
          <Image alt="" src={ImgDecorationSX} width={152} objectFit={'cover'} height={420} />
        </LeftImageDecorationContainer>
        <SantaImage>
          <Image src={Santa} alt="" width={247} height={277} objectFit={'cover'} quality={100} priority />
        </SantaImage>
        <RightImageDecorationContainer>
          <Image alt="" src={ImgDecorationDX} width={152} height={420} objectFit={'cover'} quality={100} priority />
        </RightImageDecorationContainer>
      </SantaContainer>
      <CallToAction>
        <TitleDisclaimer>
          <FormattedMessage
            defaultMessage="Give the superpower of play!"
            id="homepage_diclaimer_create_card"
            values={{ br: <br /> }}
          />
        </TitleDisclaimer>
        <Text>
          <FormattedMessage
            defaultMessage="Create the card in a few easy steps{br}and share it with your LEGO® friends."
            id="homepage_create_card_description"
            values={{ br: <br /> }}
          />
        </Text>
        <ButtonsContainer>
          <Button color="mediumViolet" onClick={onCreateButtonClick}>
            <FormattedMessage defaultMessage="Start creating" id="start_creating" />
          </Button>
          <br />
          <Button onClick={onHowItWorksButtonClick}>
            <FormattedMessage defaultMessage="How it works" id="how_it_works_title" />
          </Button>
        </ButtonsContainer>
      </CallToAction>
    </Container>
  );
}
